import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import PostLink from "../components/post-link";
import Categories from "../components/categories/Categories";
import { capitalise } from "../helpers/format";

const CategoriesPage = ({
  data: {
    site,
    allMarkdownRemark: { edges },
  },
  pageContext: { label, value },
}) => {
  const Posts = edges
    .filter(
      (edge) =>
        !!edge.node.frontmatter.date &&
        !edge.node.frontmatter.tags?.includes("draft") &&
        edge.node.frontmatter.tags?.includes(value)
    )
    .map((edge) => <PostLink key={edge.node.id} post={edge.node} />);

  return (
    <Layout>
      <Helmet>
        <title>
          {label} - {site.siteMetadata.title}
        </title>
      </Helmet>
      <Categories
        categories={site.siteMetadata.categories}
        // customLinks={[
        //   {
        //     label: "home",
        //     value: "/",
        //   },
        // ]}
      />
      <h2>{`${label} Posts`}&darr;</h2>
      <div className="grids">{Posts}</div>
    </Layout>
  );
};

export default CategoriesPage;

export const pageQuery = graphql`
  query CategoriesPageQuery {
    site {
      siteMetadata {
        title
        description
        categories {
          label
          value
        }
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            thumbnail
            tags
          }
        }
      }
    }
  }
`;
